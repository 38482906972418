import React, {useState} from "react";
import { Field } from "formik";
import { COUNTRIES } from "./util";

export const ShopInfo = ({ errors, touched, prev, next, values }) => {
  const [state, setstate] = useState(false);

  const nextHandler = () => {
    if (errors.storeName || errors.storephone || errors.storeCity || errors.industry) {
      setstate(true);
      return;
    }
    next();
  };
  return (
    <>
    <div className="">
        <div className="uk-container uk-container-large">
          {/* <div className="gap-8 d-flex-center">
            <div className="active-step"></div>
            <div className="inactive-step"></div>
            <div className="inactive-step"></div>
          </div> */}
        </div>
      </div>

    <div className="uk-grid-row-large uk-text-center">
      <span className="uk-text-bold uk-text-large">Create an account</span>
    </div>
    <div className="uk-grid-row-large uk-text-center">
      <span className="uk-text-muted uk-text-small-xs">Let's get your account ready by entering the following information.</span>
    </div>
    <div className="uk-margin-top">
      <h4 className="uk-text-bold">Store Information</h4>
      <div>
        <label className="uk-form-label" htmlFor="storeName">
          Store Name
        </label>
        <div className="uk-form-controls">
          <Field
            className={["uk-input border-radius-15", touched.storeName && errors.storeName ? "uk-form-danger" : ""].join(" ")}
            id="storeName"
            name="storeName"
            type="text"
            placeholder="Store Name*"
          />
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label" htmlFor="storephone">
          Store Phone
        </label>
        <div className="uk-form-controls">
          <Field
            className={["uk-input border-radius-15", touched.storephone && errors.storephone ? "uk-form-danger" : ""].join(" ")}
            id="storephone"
            name="storephone"
            type="text"
            value={values.storephone}
            placeholder="Store phone number"
            onInput={(evt) => {
              evt.target.value = evt.target.value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1");
            }}
          />
        </div>
      </div>

      <div className="">
        <label className="uk-form-label" htmlFor="storecity">
          Store City
        </label>

        <div className="uk-form-controls">
          <Field
            className={["uk-input border-radius-15", touched.storecity && errors.storecity ? "uk-form-danger" : ""].join(" ")}
            type="text"
            id="storecity"
            name="storecity"
            placeholder="Store city"
          />
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label" htmlFor="industry">
          Store Industry
        </label>

        <div className="uk-form-controls">
          <Field
            className={["uk-select border-radius-15", touched.industry && errors.industry ? "uk-form-danger" : ""].join(" ")}
            as="select"
            id="industry"
            name="industry"
          >
            <option value="" defaultChecked disabled={true}>Select</option>;
            <option value="Repair">Repair</option>;
            <option value="Retail">Retail</option>;
          </Field>
        </div>
      </div>

      <div className="uk-margin">
        {/* <div>
          <div>
            <span
              uk-icon="chevron-double-left"
              className="uk-button uk-button-small uk-button-primary"
              onClick={prev}
            ></span>
          </div>
        </div> */}
        <div>
          <div>
            <button className={["uk-button border-radius-15 uk-width-1-1", errors.storeName || errors.storeCity || errors.storephone || errors.industry  ? "uk-button-secondary" : "background-design"].join(
              " "
            )} onClick={nextHandler}>
               Next
            </button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
