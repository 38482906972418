// const encode = data => window.btoa(data);

// const decode = data => window.atob(data);

const encode = (data) =>
  window.btoa(window.unescape(window.encodeURIComponent(data)));

const decode = (data) =>
  window.decodeURIComponent(window.escape(window.atob(data)));

export default {
  encode,
  decode,
};
