import Spinner from "@/custom_components/Spinner/Spinner";
import { withAuthentication } from "@/hoc/withAuthentication";
import { warningAlert } from "@/utility/helpers";
import React, { useEffect } from "react";

const Container = ({ match, httpClient, history }) => {
  const report = match.params.report;
  const params = new URLSearchParams(history.location.search);

  useEffect(() => {
    httpClient
      .get(`/report/${report}?` + params.toString())
      .then((res) => {
        const { redirect } = res;
        window.location = redirect;
      })
      .catch((err) => {
        warningAlert({
          message: err?.response?.data?.message || "Transaction not found.",
          status: "danger",
        });
      });
  }, []);

  return (
    <Spinner>
      <div className="uk-light">Loading transaction receipt</div>
      <div className="uk-light uk-text-bold">Do not close this window</div>
    </Spinner>
  );
};

export default withAuthentication(Container);
