import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../constants/utility";
import { HttpFactory } from "../../RequestHandler";
import { user } from "../../constants/user";
import { useReducer } from "react";

const HttpClient = HttpFactory.getInstance();
let AuthTimeout = null;

const authStart = async (state, { dispatch, username, password }) => {
  dispatch({ type: actionTypes.AUTH_INIT });

  return await HttpClient.getAccessToken(username, password)
    .then((acct) => {
      if (acct) {
        dispatch({ type: actionTypes.AUTH_SUCCESS, acct });
        checkAuthTimeout(acct.token, dispatch);
      } else {
        return dispatch({
          type: actionTypes.AUTH_FAIL,
          error: "Username or Password not correct",
        });
      }
    })
    .catch((err) => {
      console.log(err);
      console.log("err reducer", err);
      return dispatch({ type: actionTypes.AUTH_FAIL, error: "Network error" });
    });
};

const authSuccess = (state, acct) => {
  return updateObject(state, {
    ...acct,
    error: null,
    loading: false,
  });
};

const authReset = () => {
  return updateObject(user, { error: null, loading: false });
};

const authFail = (state, error) => {
  return updateObject(state, { error, loading: false });
};

const authInit = (state) => {
  return updateObject(state, { error: null, loading: true });
};

const logout = () => {
  if (AuthTimeout) {
    clearTimeout(AuthTimeout);
  }
  HttpClient.logout();

  return authReset();
};

const checkAuthTimeout = (token, dispatch) => {
  dispatch({ type: actionTypes.SET_REFRESH_TOKEN, token });
};

const setRefreshToken = (state, token) => {
  return { ...state, token };
};

export const authReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.AUTH_LOGOUT:
      return logout();

    case actionTypes.SET_REFRESH_TOKEN:
      return setRefreshToken(state, action.token);

    case actionTypes.SET_SIDEBARSTATE:
      return updateObject(state, { sidebar: { isCollapsed: action.collapse } });

    case actionTypes.SET_TERMINAL:
      return updateObject(state, { terminal: action.terminal });

    case actionTypes.SET_PFKEY:
      return updateObject(state, { pfKey: action.pfKey });

    case actionTypes.SET_TAX:
      return updateObject(state, { tax: action.tax });

    case actionTypes.TOGGLE_TERMINAL:
      return updateObject(state, { terminalActive: action.terminalActive });

    case actionTypes.SET_SHOP:
      return updateObject(state, { selectedShop: action.shop });

    case actionTypes.UPDATE_SHOP:
      const shop = action.shop;
      const storesData = [...state.storesData];
      const idx = storesData.findIndex(({ _id }) => _id == shop?._id);
      storesData[idx] = {
        ...storesData[idx],
        ...shop,
      };
      const val = updateObject(state, { storesData });

      return val;

    case actionTypes.SET_STORELOGOIMG:
      return updateObject(state, { storeLogoImg: action.logoUrl });

    case actionTypes.SET_VENDOR:
      return updateObject(state, { vendor: action.vendor });

    case actionTypes.REFRESH_TOKEN:
      return checkAuthTimeout(action.token, action.dispatch);

    case actionTypes.AUTH_START:
      return authStart(state, action);

    case actionTypes.AUTH_INIT:
      return authInit(state);

    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action.acct);

    case actionTypes.AUTH_FAIL:
      return authFail(state, action.error);

    case actionTypes.AUTH_RESET:
      return authReset();

    default:
      throw Error("Invalid action");
  }
};

export const useAuthReducer = () => {
  return useReducer(authReducer, user);
};
