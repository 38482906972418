import React, { useEffect } from "react";
import { withAuthentication } from "../../hoc/withAuthentication";
import { user as defaultUser } from "../../constants/user";
import { withRouter } from "react-router-dom";

import Alert2 from "@/custom_components/Alert2";
import StorageUtils from "@/utility/StorageUtils";

const Signout = ({ history, user }) => {
  useEffect(() => {
    StorageUtils.clearAll();
    user.updateUser(defaultUser);
    window.localStorage.removeItem(StorageUtils.storeKey);
    history.push("/login");
  }, [user]);

  const alert = /PTPR/.test(user.referalCode) ? (
    <Alert2
      message="You currently being logged out due to security reasons."
      status="danger"
    />
  ) : null;
  return <React.Fragment>{alert}</React.Fragment>;
};

export default withAuthentication(withRouter(Signout));
