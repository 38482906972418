import React, { useState } from "react";
import { Field } from "formik";
import { digitInputHandler } from "@/constants/utility";


const AccountInfo = ({ errors, touched, values, next }) => {
  const [state, setstate] = useState(false);

  const { email, password, adminPin } = values;

  const ifNotFilled = email.trim() == "" || adminPin.trim() == "" || adminPin.length !== 4 || password.trim() == "";
  const nextHandler = () => {
    if (errors.confirmPassword || errors.email || errors.adminPin || adminPin.length !== 4 || ifNotFilled) {
      setstate(true);
      return;
    }
    next();
  };

  return (
    <>
      <div className="">
        <div className="uk-container uk-container-large">
          {/* <div className="gap-8 d-flex-center">
            <div className="active-step"></div>
            <div className="inactive-step"></div>
            <div className="inactive-step"></div>
          </div> */}
        </div>
      </div>

      <div className="uk-grid-row-large uk-text-center">
        <span className="uk-text-bold uk-text-large">Create an account</span>
      </div>
      <div className="uk-grid-row-large uk-text-center">
        <span className="uk-text-muted uk-text-small-xs">Let's get your account ready by entering the following information.</span>
      </div>

      <div className="uk-margin-top">
        <h3 className="uk-text-bold">Login Information</h3>
        <div className="uk-margin">
          <label className="uk-form-label uk-text-left" htmlFor="form-stacked-text">
            Email Address
          </label>

          <div className="uk-form-controls uk-inline uk-width-1-1@m">
            <span className="uk-form-icon" uk-icon="icon: mail"></span>
            <Field
              onInput={(e) => { e.target.value = e.target.value.toLowerCase(); }}
              className={["uk-input border-radius-15 uk-width-1-1@m", touched.email && errors.email ? "uk-form-danger" : ""].join(" ")}
              type="email"
              name="email"
              placeholder="email@youremail.com"
            />
          </div>
        </div>

        <div className="uk-margin">
          <label className="uk-form-label uk-text-left" htmlFor="form-stacked-text">
            Password
          </label>
          <div className="uk-inline uk-form-controls uk-width-1-1@m">
            <span className="uk-form-icon" uk-icon="icon: lock"></span>
            <Field
              className={["uk-input border-radius-15 uk-width-1-1@m", touched.password && errors.password ? "uk-form-danger" : ""].join(
                " "
              )}
              name="password"
              type="password"
              placeholder="password"
            />
          </div>
        </div>

        <div className="uk-margin-top">
          <label className="uk-form-label uk-text-left" htmlFor="form-stacked-text">
            Confirm Password
          </label>
          <div className="uk-inline uk-form-controls uk-width-1-1@m">
            <span className="uk-form-icon" uk-icon="icon: lock"></span>
            <Field
              className={[
                "uk-input border-radius-15 uk-width-1-1@m",
                touched.confirmPassword && errors.confirmPassword ? "uk-form-danger" : "",
              ].join(" ")}
              name="confirmPassword"
              type="password"
              placeholder="password"
            />
          </div>
        </div>
        {state && <div className="uk-text-danger uk-text-small">{errors.confirmPassword}</div>}
        <div className="uk-margin">
          <label className="uk-form-label uk-text-left" htmlFor="form-stacked-text">
            Admin Pin
          </label>
          <div className="uk-inline uk-form-controls uk-width-1-1@m">
            <span className="uk-form-icon" uk-icon="icon: lock"></span>
            <Field
              className={["uk-input border-radius-15 uk-width-1-1@m", touched.adminPin && errors.adminPin ? "uk-form-danger" : ""].join(
                " "
              )}
              onInput={(evt) => digitInputHandler(evt, 4)}
              id="adminPin"
              name="adminPin"
              type="password"
              placeholder="0000"
            />
          </div>
        </div>
        <div className="uk-margin">
          {/* <div>
          <div>
            <a href="/login">Login</a>
          </div>
        </div> */}
          <div className="">
            <span
              className={["uk-button border-radius-15 uk-width-1-1", errors.email || errors.password || errors.confirmPassword || errors.adminPin || adminPin.length !== 4 ? "uk-button-secondary" : "background-design"].join(
                " "
              )}
              // className="uk-button border-radius-15 uk-width-1-1 background-design"
              onClick={nextHandler}
            >Continue</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountInfo;
