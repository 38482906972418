import React from "react";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { digitInputHandler } from "../../../constants/utility";
import { withAuthentication } from "../../../hoc/withAuthentication";
import { Redirect, withRouter } from "react-router";
import { warningAlert } from "@/utility/helpers";

const fieldsKey = ["one", "two", "three", "four", "five", "six"];

const ConfirmationForm = ({ touched, errors, isSubmitting, user, loading }) => {
  return !user.emailVerified ? (
    <div>
      <Form className={["uk-form-stacked"].join(" ")}>
        <div className="uk-margin-auto uk-width-2-3@m">
          <div className="uk-grid uk-grid-stack uk-grid-collapse uk-flex-center uk-child-width-expand">
            {fieldsKey.map((val) => (
              <div key={val}>
                <Field
                  className={[
                    "uk-input uk-form-width-xsmall uk-text-center",
                    touched[val] && errors[val] ? "uk-form-danger" : "",
                  ].join(" ")}
                  name={val}
                  onInput={(evt) => digitInputHandler(evt, 1)}
                  type="text"
                  autoComplete={"off"}
                />
              </div>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="uk-button uk-border-pill uk-margin uk-margin-remove-bottom uk-text-capitalize uk-width-medium@m"
          disabled={isSubmitting || loading}
          style={{background:"#3A3A3A",color:"#FFFFFF"}}
        >
          {isSubmitting || loading ? <i className="fa fa-spinner fa-spin fa-fw"></i> : "Confirm Email Address"}
        </button>
        <div>
          <a href="/logout">return to login page</a>
        </div>
      </Form>
    </div>
  ) : (
    <Redirect to="/" />
  );
};

const FormikConfirmationForm = withFormik({
  mapPropsToValues({ one, two, three, four, five, six }) {
    return {
      one: one || "",
      two: two || "",
      three: three || "",
      four: four || "",
      five: five || "",
      six: six || "",
    };
  },
  validationSchema: Yup.object().shape({
    one: Yup.number().required(),
    two: Yup.number().required(),
    three: Yup.number().required(),
    four: Yup.number().required(),
    five: Yup.number().required(),
    six: Yup.number().required(),
  }),
  handleSubmit(values, { props, resetForm, setErrors, setSubmitting }) {
    const token = Object.values(values).join("");

    props.httpClient
      .post("/account/verify/" + token, { email: props.email })
      .then((data) => {
        props.user.updateUser({ emailVerified: true, ...data });

        window.location.href = "https://verified.spotspos.com";
      })
      .catch((err) => {
        props.setApiResponse({
          error: true,
          message: "Invalid verification code.",
        });
      })
      .finally((done) => {
        setSubmitting(false);
        resetForm();
      });
  },
})(ConfirmationForm);

export default withAuthentication(withRouter(FormikConfirmationForm));
