import React from "react";
import { storedData } from "../../constants/user";

export default React.createContext({
  ...storedData,
  error: null,
  terminalActive: false,
  pfKey: null,
  loading: true,
  login: () => {},
  logout: () => {},
  setShop: () => {},
  setTerminal: () => {},
  setStoreLogo: () => {},
  setStoreTax: () => {},
  setVendor: () => {},
  setCollapseSideBar: () => {},
  updateShop: () => {},
});

const HomePageContext = React.createContext({
  stats: {
    goals: 0,
    apts: 0,
    repairs: 0,
    orders: 0,
  },
  recent: {
    apts: [],
    repairs: [],
    orders: [],
  },
  quickRepairs: [],
  loading: false,
  updateGoal: (shopId, goal) => {},
});

const RepairContext = React.createContext({
  stats: {
    goals: 0,
    apts: 0,
    repairs: 0,
    orders: 0,
  },
  repairs: [],
  searchString: null,
  filteredRepairs: [],
  selectedRepair: null,
  loading: false,
  updateSearchString: (evt) => {},
});

const OrderContext = React.createContext({
  searchString: null,
  filteredOrders: [],
  selectedOrder: null,
  loading: false,
  orders: [],
  deleteOrder: () => {},
  updateOrder: () => {},
  getStoreOrders: () => {},
  updateSearchString: (evt) => {},
});

const VendorContext = React.createContext({
  _id: "",
  name: "",
  selectedShop: null,
  setSelectedShop: (evt) => {},
  setVendor: (evt) => {},
});

const RepairBuilderContext = React.createContext({
  status: "Pending",
  rating: "n/a",
  daterepaired: "n/a",
  warranty: "n/a",
  updateRepair: () => {},
  setImg: (img) => {},
});

export const EmployeeContext = React.createContext({
  employee: null,
  setEmployee: () => {},
});

export {
  OrderContext,
  HomePageContext,
  RepairContext,
  VendorContext,
  RepairBuilderContext,
};
