import moment from "moment-timezone";
import { user } from "./constants/user";
import StorageUtils from "./utility/StorageUtils";
const axios = require("axios");

const baseURL = "/api";
// const baseURL = "https://dev-portal.repairspots.com/api/";

let instance;
if (process.env.REACT_APP_NODE_ENV == "local") {
  instance = axios.create({
    timeout: 60000 * 4,
    headers: { "Content-Type": "application/json" },
  });
} else {
  instance = axios.create({
    baseURL,
    timeout: 60000 * 4,
    headers: { "Content-Type": "application/json" },
  });
}

instance.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);

    return Promise.reject(error);
  }
);

instance.defaults.crossdomain = true;
// axios.defaults.withCredentials = true;

const timeZone = moment.tz.guess();

class HttpClient {
  constructor(token) {
    this.client = instance;
    this.token = token;
  }

  setToken(token) {
    this.token = token;
    return this;
  }

  getToken() {
    return this.token;
  }

  get(endpoint) {
    return this.client
      .get("/v3" + endpoint, {
        headers: { Authorization: "Bearer " + this.token.access_token },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          //getaccess token and recall request
          return this.refreshToken().then((res) => {
            return this.get(endpoint);
          });
        } else {
          throw error;
        }
      });
  }

  post(endpoint, body) {
    if (!body) {
      body = {};
    }
    const reqBody = body.timeZone ? body : { ...body, timeZone };
    return this.client
      .post("/v3" + endpoint, reqBody, {
        headers: { Authorization: "Bearer " + this.token.access_token },
      })
      .then((res) => {
        return res.data;
      })
      .catch(async (error) => {
        if (error && error.response && error.response.status === 401) {
          //getaccess token and recall request
          return await this.refreshToken().then((res) => {
            return this.post(endpoint, body);
          });
        } else {
          throw error;
        }
      });
  }

  put(endpoint, body) {
    if (!body) {
      body = {};
    }
    const reqBody = body.timeZone ? body : { ...body, timeZone };
    return this.client
      .put("/v3" + endpoint, reqBody, {
        headers: { Authorization: "Bearer " + this.token.access_token },
      })
      .then((res) => {
        return res.data;
      })
      .catch(async (error) => {
        if (error && error.response && error.response.status === 401) {
          //getaccess token and recall request
          return await this.refreshToken().then((res) => {
            return this.put(endpoint, body);
          });
        } else {
          throw error;
        }
      });
  }

  delete(endpoint, body) {
    return this.client
      .delete("/v3" + endpoint, {
        data: body,
        headers: { Authorization: "Bearer " + this.token.access_token },
      })
      .then((res) => {
        return res.data;
      })
      .catch(async (error) => {
        if (error && String(error).includes("401")) {
          //getaccess token and recall request
          return await this.refreshToken().then((res) => {
            return this.delete(endpoint, body);
          });
        } else {
          throw error;
        }
      });
  }

  getAccessToken(username, password) {
    return this.client({
      method: "post",
      url: "/v3/account/login",
      data: {
        email: username,
        password: password,
      },
    }).then((res) => {
      const data = res.data.email ? res.data : null;

      data?.token && StorageUtils.setToken(data?.token);

      StorageUtils.setItem(StorageUtils.storeKey, data);

      return data;
    });
  }

  initResendToken(email) {
    return this.client({
      method: "post",
      url: "/v3/2fa/resend",
      data: { email },
    }).then((res) => {
      return res.data;
    });
  }

  initTwoFactorVerification(email, token) {
    return this.client({
      method: "post",
      url: "/v3/2fa/verify",
      data: { email, token },
    })
      .then((res) => {
        const data = res.data.email ? res.data : null;

        StorageUtils.setItem(StorageUtils.storeKey, data);

        return data;
      })
      .catch((error) => {
        console.log("error here verificatioin", error);
        if (error && error.response && error.response.status === 401) {
          return null;
        }

        throw error;
      });
  }

  initTwoFactorLogin(username, password) {
    return this.client({
      method: "post",
      url: "/v3/2fa/login",
      data: {
        email: username,
        password: password,
      },
    })
      .then((res) => {
        const data = res.data.twoFactor || res.data.email ? res.data : null;
        data?.token && StorageUtils.setToken(data?.token);

        if (res.data.email) {
          const { token, ...acct } = data;
          StorageUtils.setItem(StorageUtils.storeKey, acct);
        }

        return data;
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          return null;
        }
        throw error;
      });
  }

  setExpirationTime(expires_in) {
    const exp = moment().utc().add({ seconds: expires_in }).format();
    this.expires = exp;
  }

  hasExpired() {
    const current = moment.utc().format();
    return moment(current).isAfter(this.expires);
  }

  logout() {
    // this.client.post("/v3/refresh_token/revoke", this.token).catch((e) => e);
    this.client.get("/v3/account/logout").then((e) => e);
    StorageUtils.setItem(StorageUtils.storeKey, user);
    window.open("/logout", "_self");
  }

  // Handle expire token
  refreshToken() {
    return this.client
      .post("/v3/token/refresh", this.token, {
        headers: { Authorization: "Bearer " + this.token.access_token },
      })
      .then((res) => {
        this.token = res.data;
        return this.token;
      })
      .catch((error) => {
        // console.log("error", error)
        if (String(error).includes("401") || String(error).includes("400")) {
          this.logout();
          window.open("/logout", "_self");
          return null;
        }

        return this.token;
      });
  }
}

const Http = new HttpClient(null);

export class HttpFactory {
  static getInstance() {
    const token = {
      token_type: "bearer",
      expires_in: 300,
      access_token: null,
      refresh_token: null,
    };

    Http.setToken(StorageUtils.getToken() || token);

    return Http;
  }
}

export default HttpClient;

export { instance as AxiosInstance };
