import { getActiveShop, isNotSafari, noop } from "@/constants/utility";
import * as moment from "moment-timezone";
import StorageUtils from "./StorageUtils";

export const warningAlert = ({ message = "You're all caught up.", status = "danger" }) => {
  window.UIkit.notification({
    message: `<div class='uk-text-center uk-text-small'>${message}</div>`,
    status,
    timeout: 10000,
  });
};

export const xhrRequestJSON = (url, type = "GET", data, done = noop) => {
  var xhr = new XMLHttpRequest();
  // xhr.withCredentials = true;
  // xhr.responseType = "json";
  xhr.addEventListener("readystatechange", function () {
    if (this.readyState === 4) {
      done(this.responseText);
    }
  });

  xhr.open(type, url);
  xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
  xhr.send(data);
};

export const xhrRequest = (url, type = "GET", data, done = noop) => {
  var xhr = new XMLHttpRequest();
  // xhr.withCredentials = true;
  // xhr.responseType = "json";
  xhr.addEventListener("readystatechange", function () {
    if (this.readyState === 4) {
      done(this.responseText);
    }
  });

  xhr.open(type, url);
  xhr.send(data);
};

export function openInNewTab(href, blank = true) {
  Object.assign(document.createElement("a"), {
    target: blank ? "_blank" : null,
    rel: "noopener noreferrer",
    href: href,
  }).click();
}

export function gotoPage(href) {
  Object.assign(document.createElement("a"), {
    href: href,
  }).click();
}

export async function printPdf(url) {
  if (!isNotSafari()) {
    //   warningAlert({ message: "Disable popup blocker to print." });
    openInNewTab(url);
    return;
  }
  setTimeout(() => {
    try {
      window.printJS(
        {
          printable: url,
          type: "pdf",
          showModal: true,
        },
        1000
      );
    } catch (error) {
      alert(error);
    }
  });
}

export function utcToLocalDateTime(date) {
  var offset = moment().utcOffset();
  return moment.utc(date).utcOffset(offset);
}

export function utcToTimezone(date, tz) {
  const utc = moment.utc(date);
  return utc.clone().tz(tz || "America/Los_Angeles");
}

export const doInterval = (func, time) => {
  let interval;

  const recursive = () => {
    interval = setTimeout(() => {
      try {
        recursive();
        func(interval);
      } catch (e) {
        clearTimeout(interval);
      }
    }, time);
  };

  recursive();
};

export const getShopCurrency = () => {
  const shop = getActiveShop(StorageUtils.getItem(StorageUtils.storeKey));

  return shop.currencyPortal || "$";
};

export function getParams(url = window.location) {
  // Create a params object
  let params = {};

  new URL(url).searchParams.forEach(function (val, key) {
    if (params[key] !== undefined) {
      if (!Array.isArray(params[key])) {
        params[key] = [params[key]];
      }
      params[key].push(val);
    } else {
      params[key] = val;
    }
  });

  return params;
}

export function isBrowserHasPDFViewer() {
  // Modern borwsers
  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/pdfViewerEnabled
  if (navigator.pdfViewerEnabled !== undefined) {
    return navigator.pdfViewerEnabled;
  }

  // Old browsers or not compatible with pdfViewerEnabled like Safari
  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/mimeTypes
  let hasPDFViewer = false;
  try {
    var hasPluginEnabled =
      navigator.mimeTypes && navigator.mimeTypes["application/pdf"]
        ? navigator.mimeTypes["application/pdf"].enabledPlugin
        : null;
    if (hasPluginEnabled) {
      hasPDFViewer = true;
    }
  } catch (e) {
    hasPDFViewer = false;
  }

  return hasPDFViewer;
}

export const loadRepairPrintData = (repair,user) => {
  const samleData = {
    "invoice_id": `repair_details_0${repair?.repairID}`,
    "repairid": "R-321",
    "languaje": "en",
    "store_name": "Employee Manager Inc.",
    "store_address": "1234 sample road Aguada, 00602. US",
    "customer_name": "BIN HANIF",
    "customer_address": "ELECTROMECHANICAL WORKS LLC TRN:100001464500003",
    "receipt": "Repair Details",
    "theme": "red",
    "var_date": "May 18, 2021",
    "var_employee": "Raymond Cordero",
    "var_technician": "Carlos Soto",
    "var_status": "Pending",
    "var_device": "abc",
    "var_defective_parts": "LCD",
    "var_imei_serial": "12345342156",
    "var_estimated_repair_cost": "50",
    "terms_and_conditions": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    "logo": "https://web.spotspos.com/static/media/logors.png",
    "currency": "$",
    "payment_history": [
      {
        "var_date": "3/1/2021",
        "var_payment_type": "Cash",
        "var_amount": "100"
      },
      {
        "var_date": "3/1/2021",
        "var_payment_type": "Debit",
        "var_amount": "120"
      }
    ],
    "status_history": [
      {
        "var_date": "3/1/2021",
        "var_status_history": "Repaired"
      },
      {
        "var_date": "3/1/2021",
        "var_status_history": "Repaired"
      }
    ],
    "notes": [
    ],
    "parts": [
      {
        "title": "part 0",
        "status": true
      },
      {
        "title": "part 1",
        "status": false
      },
      {
        "title": "part 2",
        "status": true
      },
      {
        "title": "part 3",
        "status": true
      },
      {
        "title": "part 4",
        "status": false
      },
      {
        "title": "part 5",
        "status": true
      },
      {
        "title": "part 6",
        "status": true
      },
      {
        "title": "part 7",
        "status": false
      },
      {
        "title": "part 8",
        "status": true
      },
      {
        "title": "part 9",
        "status": true
      },
      {
        "title": "part 10",
        "status": false
      },
      {
        "title": "part 11",
        "status": true
      },
      {
        "title": "part 12",
        "status": true
      },
      {
        "title": "part 13",
        "status": false
      },
      {
        "title": "DOA",
        "status": false
      }
    ]
  }
}
