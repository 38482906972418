import React, { useState, useEffect } from "react";
import Spinner from "../../custom_components/Spinner/Spinner";
import { HttpFactory, AxiosInstance } from "../../RequestHandler";
import ProtectedRoute from "@/custom_components/ProtectedRoute";
import { withAuthentication } from "@/hoc/withAuthentication";
import { withRouter } from "react-router-dom";

const PtprAuth = ({ history, match, user }) => {
  const [alert, setAlert] = useState(false);
  const { access_token } = match.params;

  useEffect(() => {
    const httpClient = HttpFactory.getInstance();
    httpClient.setToken({ access_token });

    AxiosInstance.post(
      "/v3/account/ptpr-login",
      {},
      {
        headers: { Authorization: "Bearer " + access_token },
      }
    )
      .then((res) => {
        const data = res.data.email ? res.data : null;
        if (data) {
          httpClient.setToken(data.token);

          user.updateUser(data);
          user.setShop(data.stores[0]);
          window.location.href = "/";
          return;
        }
        throw "Unauthorized access";
      })
      .catch((error) => {
        setAlert(true);
      });
  }, []);

  return (
    <Spinner>
      <ProtectedRoute
        pathname={"/logout"}
        condition={!alert}
        message={"You're not authorized to access this route"}
      />
      <div className="uk-light">Authenticating user...</div>
      <div className="uk-light uk-text-bold">Do not close this window</div>
    </Spinner>
  );
};

export default withAuthentication(withRouter(PtprAuth));
