import { warningAlert } from "@/utility/helpers";
import React, { useEffect } from "react";
import { withRouter } from "react-router";
import * as actionTypes from "../../context/actions/actionTypes";
import Modal from "../../custom_components/Modal";

const ShopLogin = ({ user, history }) => {
  const { storesData, dispatch, stores } = user;

  useEffect(() => {
    if (stores.length == 1 || /accountant/i.test(user.type)) {
      initSelectedShop(storesData[0]);
    }
  }, [stores]);

  const initSelectedShop = ({ _id, plan, isSuspended, status }) => {
    if (/true/i.test(isSuspended)) {
      // return dispatch({
      //   type: actionTypes.AUTH_FAIL,
      //   error:
      //     "Your store is currently suspended. Contact us at info@repairspots.com",
      // });
      warningAlert({
        message: `Your store is currently suspended. Contact us at support@spotspos.com`,
      });
      return history.push("/logout");
    }

    user.setShop(_id);

    const { emailVerified } = user;
    if (!emailVerified) {
      setTimeout(() => {
        window.location.href = "/verification";
      }, 500);
      return;
    }

    if (/^(Free)$/i.test(plan)) {
      return dispatch({
        type: actionTypes.AUTH_FAIL,
        error: "Please upgrade to a different plan to access the portal.",
      });
    }

    if (/accountant/i.test(user.type)) {
      return history.push("/multi-manager");
    }
  };

  const getStoreList = (data) => {
    return data.map(({ _id, name, isSuspended, status, plan }) => {
      return (
        <li uk-toggle={"target: #" + _id + ";mode: hover; cls: uk-hover-color;"} id={_id} key={_id}>
          <a
            onClick={() => initSelectedShop({ status, _id, plan, isSuspended })}
            target={status != "Active" ? "_blank" : ""}
            rel="noopener noreferrer"
            className="shopLink"
          >
            {status == "Active" && !/true/i.test(isSuspended) ? (
              name
            ) : (
              <span className="uk-text-small uk-text-danger">{name}: suspended</span>
            )}
          </a>
        </li>
      );
    });
  };

  let loginShop = getStoreList(storesData);

  if (/^(StoreAdmin)$/i.test(user.type)) {
    loginShop = getStoreList(user.adminstores.map((val) => storesData.find(({ _id }) => _id == val)));
  }

  let render = null;

  if (/^(Admin|StoreAdmin)$/i.test(user.type) && user.email && user.stores.length > 0 && !user.selectedShop) {
    render = (
      <Modal title="Select a shop to Login">
        <ul className="uk-list uk-list-divider uk-nav-default uk-list-striped" uk-nav={1}>
          {loginShop}
        </ul>
      </Modal>
    );
  }

  return render;
};

export default withRouter(ShopLogin);
