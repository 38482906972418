export const getRandomNumber = (length) =>
  Math.floor(
    Math.pow(10, length - 1) +
      Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)
  );

export const getRandomString = (length = 2) => {
  return window
    .btoa(
      Array.from(window.crypto.getRandomValues(new Uint8Array(length * 2)))
        .map((b) => String.fromCharCode(b))
        .join("")
    )
    .replace(/[+/]/g, "")
    .substring(0, length)
    .toUpperCase();
};

export const to2DP = (num = 0) => {
  return Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100;
};

export const objEmptyStringToUndefined = (obj) => {
  return JSON.parse(
    JSON.stringify(obj, function (key, value) {
      return value === "" ? undefined : value;
    }) || "{}"
  );
};

export const numberInputHandler = (evt) => {
  const val = evt.target.value.replace(/[^-0-9.]/g, "");
  evt.target.value = val.replace(/(\..*)\./g, "$1");

  return evt.target.value;
};

export const decimalInputHandler = (evt) => {
  const val = evt.target.value.replace(/[^0-9.]/g, "");
  evt.target.value = val.replace(/(\..*)\./g, "$1");

  return evt.target.value;
};

export const digitInputHandler = (evt, allowed = 10e10) => {
  const value = (evt.target.value = evt.target.value.replace(/[^0-9]/g, ""));
  evt.target.value = value.replace(/(\..*)\./g, "$1").substr(0, allowed);
  return evt.target.value;
};

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const compose =
  (...functions) =>
  (args) =>
    functions.reduceRight((arg, fn) => fn(arg), args);

export const queryString = (obj) =>
  "?" +
  Object.keys(obj)
    .filter((k) => !!obj[k])
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join("&");

export const createReminderTask = (obj) => {
  return [
    ...createAptTask(obj),
    ...createMeetingTask(obj),
    ...createOrdersTask(obj),
    ...createRepairsTask(obj),
  ].sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });
};

const createRepairsTask = ({ repairs }) => {
  return repairs.map(({ repairshop, _id, device, status, date_submitted }) => {
    return {
      type: "Repair",
      reference: _id,
      shopId: repairshop,
      name: device,
      status,
      date: date_submitted,
    };
  });
};

const createOrdersTask = ({ orders }) => {
  return orders.map(({ repairshop, _id, part, status, _date }) => {
    return {
      type: "Special Order",
      reference: _id,
      shopId: repairshop,
      name: part,
      status,
      date: _date,
    };
  });
};

const createAptTask = ({ apts }) => {
  return apts
    .filter(({ type }) => type != "Appointment")
    .map(({ repairshop, _id, subject, status, date_submitted, device }) => {
      return {
        type: "Appointment",
        reference: _id,
        shopId: repairshop,
        name: device,
        status,
        date: date_submitted,
      };
    });
};

const createMeetingTask = ({ apts }) => {
  return apts
    .filter(({ type }) => type != "Meeting")
    .map(({ repairshop, _id, subject, status, date_submitted, device }) => {
      return {
        type: "Meeting",
        reference: _id,
        shopId: repairshop,
        name: device,
        status,
        date: date_submitted,
      };
    });
};

export function chunkArray(array, size) {
  if (!array) return [];
  const firstChunk = array.slice(0, size); // create the first chunk of the given array
  if (!firstChunk.length) {
    return array; // this is the base case to terminal the recursive
  }
  return [firstChunk].concat(chunkArray(array.slice(size, array.length), size));
}

export const groupBy = (keys) => (array) =>
  array?.reduce((objectsByKeyValue, obj) => {
    const value = keys.map((key) => obj[key]).join("-");
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export const noop = () => {};

export const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function () {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function () {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

export function debounce(callback, wait) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;

    clearTimeout(timeout);
    timeout = setTimeout(function () {
      callback.apply(context, args);
    }, wait);
  };
}

export function reloadPage() {
  window.location.href =
    window.location.pathname + window.location.search + window.location.hash;
  // creates a history entry
}

export const refreshPage = (history) => {
  const current = window.location.pathname;
  history.replace(`/reload`);
  setTimeout(() => {
    history.replace(current);
  });
};

export const parseNumber = (val = "") => {
  const num = val ? val.replace(/[^-0-9.]/g, "") : 0;

  return +num || 0;
};

export const partitionArray = (array, size) =>
  array.reduce((acc, _, i) => {
    if (i % size === 0) acc.push(array.slice(i, i + size));
    return acc;
  }, []);

export const getActiveShop = (user) => {
  const shopId = user.selectedShop;
  const shop = user.storesData.find(({ _id }) => _id == shopId);

  return shop;
};

export const calculateTax = ({ shopTax = 0, price }) => {
  const tax = (parseFloat(shopTax) * parseFloat(price)) / 100;
  return tax;
};

export const shuffleArray = (arr) =>
  arr.sort(function (a, b) {
    return 0.5 - Math.random();
  });

export const luhnCheck = (num) => {
  let arr = (num + "")
    .split("")
    .reverse()
    .map((x) => parseInt(x));
  let lastDigit = arr.splice(0, 1)[0];
  let sum = arr.reduce(
    (acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val * 2) % 9) || 9),
    0
  );
  sum += lastDigit;
  return sum % 10 === 0;
};

export const getIframe = (iframeId) => {
  const iframe = document.querySelector(iframeId);
  return iframe.contentDocument
    ? iframe.contentDocument
    : iframe.contentWindow.document;
};

export const isNotSafari = () => !/apple/i.test(navigator?.vendor);
