import EncryptionUtils from "./EncryptionUtils";

const storeKey = "repairspots_data";
const tokenKey = "repairspots_token";

const storageMechanism = window.localStorage; // or window.sessionStorage;

const setItem = (key, value) => {
  const encodedValue = EncryptionUtils.encode(JSON.stringify(value));
  storageMechanism.setItem(key, encodedValue);
};

const getItem = (key) => {
  const value = storageMechanism.getItem(key);
  if (value) {
    return JSON.parse(EncryptionUtils.decode(value));
  }

  return null;
};

const removeItem = (key) => {
  storageMechanism.removeItem(key);
};

const clearAll = () => {
  storageMechanism.clear();
};

const setToken = (token) => {
  setItem(tokenKey, token);
};

const getToken = () => getItem(tokenKey);

export default {
  setItem,
  getItem,
  removeItem,
  clearAll,
  storeKey,
  setToken,
  getToken,
};
