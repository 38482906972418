import React from "react";
import classes from "./Spinner.module.css";

const Spinner = ({ children }) => {
  return (
    <div className={[classes.Loader, "uk-text-center"].join(" ")}>
      <div className="uk-display-block" uk-spinner="ratio: 4.5"></div>
      {children}
    </div>
  );
};

export default Spinner;
