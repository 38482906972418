import React, { useEffect, useReducer, useState } from "react";
import { storedData } from "../constants/user";
import * as actions from "../context/actions/actionTypes";
import PortalContext, { EmployeeContext } from "../context/contexts/portal-context";
import { authReducer } from "../context/reducers/auth";
import { HttpFactory } from "../RequestHandler";
import StorageUtils from "@/utility/StorageUtils";
import { SpinnerProvider } from "@/context/contexts/SpinnerContext";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "@/custom_components/ErrorFallback";

const GlobalState = (props) => {
  const [user, dispatch] = useReducer(authReducer, { ...storedData });
  const shop = user?.storesData?.find(({ _id }) => _id == user?.selectedShop);
  const isRetail = shop?.industry && shop?.industry !== "Repair";
  const [employee, setEmployee] = useState();

  useEffect(() => {
    StorageUtils.setItem(StorageUtils.storeKey, user);
  }, [user]);

  const login = (username, password) => {
    dispatch({ type: actions.AUTH_START, username, password, dispatch });
  };

  const setShop = (shop) => {
    dispatch({ type: actions.SET_SHOP, shop });
  };

  const setTerminal = (terminal) => {
    dispatch({ type: actions.SET_TERMINAL, terminal });
  };

  const setPfKey = (pfKey) => {
    dispatch({ type: actions.SET_PFKEY, pfKey });
  };

  const setStoreTax = (tax) => {
    dispatch({ type: actions.SET_TAX, tax });
  };

  const setTerminalActive = (terminalActive) => {
    dispatch({ type: actions.TOGGLE_TERMINAL, terminalActive });
  };

  const setVendor = (vendor) => {
    dispatch({ type: actions.SET_VENDOR, vendor });
  };

  const logout = () => {
    dispatch({ type: actions.AUTH_LOGOUT });
  };

  const setStoreLogo = (logoUrl) => {
    dispatch({ type: actions.SET_STORELOGOIMG, logoUrl });
  };

  const setCollapseSideBar = (collapse) => {
    dispatch({ type: actions.SET_SIDEBARSTATE, collapse });
  };

  const updateShop = (shop) => {
    dispatch({ type: actions.UPDATE_SHOP, shop });
  };

  const updateUser = (user) => {
    dispatch({ type: actions.AUTH_SUCCESS, acct: user });
  };

  return (
    <PortalContext.Provider
      value={{
        ...user,
        isRetail,
        error: user.error,
        loading: user.loading,
        selectedShop: user.selectedShop,
        dispatch,
        login,
        logout,
        setShop,
        setTerminal,
        setStoreLogo,
        setVendor,
        setCollapseSideBar,
        updateShop,
        updateUser,
        setTerminalActive,
        setPfKey,
        setStoreTax,
      }}
    >
      <SpinnerProvider>
        <EmployeeContext.Provider value={{ employee, setEmployee }}>
          {/* <ErrorBoundary FallbackComponent={ErrorFallback}> */}
          {props.children}
          {/* </ErrorBoundary> */}
        </EmployeeContext.Provider>
      </SpinnerProvider>
    </PortalContext.Provider>
  );
};

export default GlobalState;
