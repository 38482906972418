import React, { useEffect } from "react";
import { withRouter } from "react-router";
import Loader from "@/custom_components/Preloader/Preloader";
import { withAuthentication } from "@/hoc/withAuthentication";
import { warningAlert } from "@/utility/helpers";

const TokenVerification = ({ match, history, user, httpClient }) => {
  const { email } = match.params;
  const params = new URLSearchParams(history.location.search);
  const token = params.get("token");

  console.log(token, email);

  useEffect(() => {
    httpClient
      .post("/account/verify/" + token, { email })
      .then((res) => {
        warningAlert({ message: "Verification successful", status: "success" });
        // user.updateUser({ selectedShop: res.storeId, ...res });
        setTimeout(() => {
          history.push("/logout");
          window.location.href = "https://verified.repairspots.com";
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        warningAlert({ message: "Verfication failed", status: "danger" });
        setTimeout(() => {
          history.push("/logout");
        }, 1500);
      });
  }, []);
  return <Loader />;
};

export default withAuthentication(withRouter(TokenVerification));
