import React, { useState } from "react";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import Alert from "../../../custom_components/alert";
import AccountInfo from "./AccountInfo";
import ContactInfo from "./ContactInfo";
import { ShopInfo } from "./ShopInfo";
import { withAuthentication } from "../../../hoc/withAuthentication";
import { withRouter } from "react-router";
import { warningAlert } from "@/utility/helpers";
import logo from "../../../assets/images/logo-rs.png";


const Register = (props) => {
  const [step, setStep] = useState(1);

  const next = () => {
    setStep((s) => Math.min(3, s + 1));
  };

  const prev = () => {
    setStep((s) => Math.max(1, s - 1));
  };

  const { errors, values } = props;

  return (
    <div className="uk-container uk-padding">
      <div className="uk-width-large@m uk-text-center uk-margin-top uk-margin-auto">
        {" "}
        {!!errors.api && <Alert>{errors.api}</Alert>}
      </div>
      <div className="uk-width-large@m  uk-margin-auto uk-box-shadow-small uk-padding uk-padding-remove-top">
        <Form className="uk-form-stacked">
        <div className="uk-image uk-text-center">
        <img
            className="uk-margin-top"
            src={'/icons/header/logo-sp.svg'}
            width="65%"
            height="auto"
            alt="SpotsPOS"
          />
        </div>
          <Field type="hidden" value={props.match.params.referral} name="referalCode" />
          {step == 1 && <AccountInfo {...props} next={next} />}
          {step == 2 && <ShopInfo {...props} next={next} prev={prev} />}
          {step == 3 && <ContactInfo {...props} prev={prev} />}
        </Form>
      </div>
    </div>
  );
};

const FormikRegister = withFormik({
  mapPropsToValues({
    email,
    phone,
    firstName,
    lastName,
    storeName,
    storecity,
    adminPin,
    storephone,
    confirmPassword,
    password,
    gender,
    referalCode,
    country,
    industry,
  }) {
    return {
      email: email || "",
      password: password || "",
      storeName: storeName || "",
      confirmPassword: confirmPassword || "",
      firstName: firstName || "",
      lastName: lastName || "",
      gender: gender || "",
      adminPin: adminPin || "",
      phone: phone || "",
      storephone: storephone || "",
      storecity: storecity || "",
      referalCode: referalCode || "",
      country: country || "",
      industry: industry || "",
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required("Password is required"),
    adminPin: Yup.string().required("Admin pin is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords don't match")
      .required("Confirm Password is required"),
    storeName: Yup.string().required(),
    storecity: Yup.string().required(),
    // country: Yup.string().required(),
    industry: Yup.string().required(),
    storephone: Yup.string().required(),
    gender: Yup.string().required(),
    phone: Yup.number().required().min(10),
    // city: Yup.string().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
  }),
  handleSubmit(values, { props, resetForm, setErrors, setSubmitting }) {
    console.log(values,'values');
    const referral = props.match.params.referral;
    values.referalCode = referral || "";

    props.httpClient
      .post("/account/signup_and_shop", values)
      .then((res) => {
        warningAlert({
          message: "Registration successful. Please check your email inbox or spam for verification link.",
          status: "success",
        });
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      })
      .catch((err) => {
        if (err.response) {
          setErrors({ api: err.response.data });
          return;
        }
        setErrors({ api: "something went wrong. Try again." });
      })
      .finally((done) => {
        setSubmitting(false);
      });
  },
})(Register);

export default withRouter(withAuthentication(FormikRegister));
