import StorageUtils from "@/utility/StorageUtils";

export const user = {
  email: null,
  _id: null,
  storeId: null,
  selectedShop: null,
  type: null,
  firstName: null,
  lastName: "",
  phoneNumber: "",
  city: "",
  gender: "",
  stores: [],
  vendor: {},
  storesData: [
    {
      _id: "",
      status: "Suspended",
      email: "",
      name: "",
    },
  ],
  adminstores: [],
  token: {
    token_type: "bearer",
    expires_in: 300,
    access_token: null,
    refresh_token: null,
  },
  sidebar: {
    isCollapsed: false,
  },
};

let storedData;

try {
  storedData = StorageUtils.getItem(StorageUtils.storeKey);

  storedData = storedData._id ? { ...user, ...storedData } : user;
} catch (e) {
  storedData = user;
}

const HomePage = {
  stats: {
    goals: 0,
    apts: 0,
    repairs: 0,
    orders: 0,
  },
  recent: {
    apts: [],
    repairs: [],
    orders: [],
  },
  quickRepairs: [],
  error: null,
  loading: false,
};

export { storedData, HomePage };
