import React, { useContext, useLayoutEffect } from "react";
import { HttpFactory } from "../RequestHandler";
import portalContext from "../context/contexts/portal-context";
import StorageUtils from "@/utility/StorageUtils";

export const withAuthentication = (Component) => (props) => {
  const context = useContext(portalContext);

  useLayoutEffect(() => {
    // const zendesk = document.querySelector("iframe[title *='Button to launch messaging window']");

    // if (zendesk) {
    //   zendesk.parentElement.style.display = /^(\/|\/pricing)$/i.test(window.location.pathname) ? "" : "none";
    // }

    if (!/^(\/|\/pricing)$/i.test(window.location.pathname)) {
      window.zE?.("messenger", "hide");
    } else {
      window.zE?.("messenger", "show");
    }
  });

  const shopId = context.selectedShop;
  const shop = (context.storesData || []).find(({ _id }) => _id == shopId);

  const token = {
    token_type: "bearer",
    expires_in: 300,
    access_token: null,
    refresh_token: null,
  };

  const requestClient = HttpFactory.getInstance();
  requestClient.setToken(StorageUtils.getToken() || token);
  return <Component {...props} user={context} loggedInShop={shopId ? shop : {}} httpClient={requestClient} />;
};
