import React, { useEffect, useState } from "react";
import { withFormik, Form, Field } from "formik";
import logo from "../../../assets/images/logo-rs.png";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import { compose } from "../../../constants/utility";
import { withAuthentication } from "../../../hoc/withAuthentication";
import { Link } from "react-router-dom";
import Alert from "../../../custom_components/alert";

const Login = ({
  values,
  errors,
  touched,
  isSubmitting,
  setSubmitting,
  setErrors,
  user,
  history,
}) => {
  const { error } = user;

  useEffect(() => {
    if (error) {
      setErrors({ user: error });
    }
  }, [error]);

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className="">
        <Form className="uk-form-stacked">
          <img
            className=""
            src={'/icons/header/logo-sp.svg'}
            width="65%"
            height="auto"
            alt="SpotsPOS"
          />
          <div className="uk-grid-row-large">
            <span className="uk-text-bold uk-text-large">Welcome Back</span>
          </div>
          <div className="uk-grid-row-large">
            <span className="uk-text-muted uk-text-small">Please enter your email address and password.</span>
          </div>
          {error && <Alert>{errors.user}</Alert>}
          <div className="uk-margin">
            <label
              className="uk-form-label uk-text-left"
              htmlFor="form-stacked-text"
            >
              Email
              {(touched.email || errors.email) && (
                <span className="uk-text-danger uk-text-small uk-margin-left">
                  {errors.email}
                </span>
              )}
            </label>

            <div className="uk-form-controls uk-inline uk-width-expand@m">
              <span className="uk-form-icon" uk-icon="icon: user"></span>
              <Field
                onInput={(e) => { e.target.value = e.target.value.toLowerCase(); }}
                className={[
                  "uk-input border-radius-15",
                  errors.email ? "uk-form-danger" : "",
                ].join(" ")}

                type="email"
                name="email"
                placeholder="email address"
              />
            </div>
          </div>

          <div className="uk-margin">
            <label
              className="uk-form-label uk-text-left"
              htmlFor="form-stacked-text"
            >
              Password
              {touched.password && errors.password && (
                <span className="uk-text-danger uk-text-small uk-margin-left">
                  {errors.password}
                </span>
              )}
            </label>
            <div className="uk-inline uk-form-controls uk-width-expand@m">
              <span className="uk-form-icon" uk-icon="icon: lock"></span>
              <Field
                className="uk-input border-radius-15"
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="password"
              />
              <a
                onClick={() => setShowPassword((e) => !e)}
                className={`fa ${!showPassword ? "fa-eye" : "fa-eye-slash"
                  } uk-form-icon uk-form-icon-flip`}
                aria-hidden="true"
              ></a>
            </div>
          </div>


          <div className="uk-grid-row-large uk-float-right">
            <a
              href="/forgot"
              target="_blank"
              rel="noopener noreferrer"
              className="color-design font-14 uk-text-bold"
            >
              Forgot your password?
            </a>
          </div>


          <button
            type="submit"
            className=" uk-margin-large-top border-radius-15 uk-button background-design uk-width-1-1"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <i className="fa fa-spinner fa-spin fa-fw"></i>
            ) : (
              "Continue"
            )}
          </button>

          <div className="uk-grid-row-large font-14 uk-margin-top uk-margin-bottom">
            <span className="uk-text-bold">Don't have an account? <Link to="/register" className="color-design uk-margin-small-left">
              Register
            </Link></span>

          </div>
        </Form>
      </div>
    </>
  );
};

const FormikLogin = withFormik({
  mapPropsToValues({ email, password }) {
    return {
      email: email || "",
      password: password || "",
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  }),
  handleSubmit(values, { props, resetForm, setErrors, setSubmitting }) {
    props.initLogin(values).then((data) => {
      setSubmitting(false);
    });
  },
})(Login);

const LoginForm = compose(withAuthentication, withRouter)(FormikLogin);

export default LoginForm;
