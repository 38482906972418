import React, { Suspense } from "react";

// import asyncComponent from './hoc/asyncComponent'
import Auth from "./components/Auth/Auth";
import PtprAuth from "./components/Auth/PtprAuth";
import Loader from "./components/Loader/Loader";
import { Route, Switch, HashRouter, Redirect } from "react-router-dom";
import GlobalState from "./components/GlobalState";

import Signout from "./components/Auth/Signout";
import Register from "./components/Auth/Register/Register";
import EmailVerification from "./components/Auth/Verification/Verification";
import TokenVerification from "./components/Auth/Verification/TokenVerification";

import Payment from "./components/Receipt/Payment";
import RepairReceipt from "./components/Receipt/Repair";
import ReceiptContainer from "./components/Receipt/Container";
import Processing from "./components/Pricing/Processing";
import Help from "./components/Help/Help";
import Plaid from "./components/Payrix/Plaid";
import AccountantRestrictedRoute from "./custom_components/AccountantRestrictedRoute";
import EmailForgot from "./components/Auth/ForgetPassword/EmailForgot";
import PasswordReset from "./components/Auth/ForgetPassword/PasswordReset";
const Subscription = React.lazy(() => import("./components/Pricing/SubContainer"));

const Layout = React.lazy(() => import("./components/Layout/Layout"));

const RDetails = React.lazy(() => import("./components/Repair/RDetails/RDetails"));
const EmployeeDetails = React.lazy(() => import("./components/Employee/EmployeeDetails"));
const Employee = React.lazy(() => import("./components/Employee/Employee"));

const ReportContainer = React.lazy(() => import("./components/Reports/ReportContainer"));

const BulkUpload = React.lazy(() => import("./components/BulkUpload/BulkUpload"));
const InventoryGrid = React.lazy(() => import("./components/Inventory/InventoryGrid"));
const Inventory = React.lazy(() => import("./components/Inventory/LatestInventory/inventory"));
const Homepage = React.lazy(() => import("./components/Homepage/HomePage"));
const Repair = React.lazy(() => import("./components/Repair/Repair"));
const LastLoginGrid = React.lazy(() => import("./components/LastLogin/LastLoginGrid"));
const SettingsContainer = React.lazy(() => import("./components/Settings/SettingContainer"));
const CreateRepairGrid = React.lazy(() => import("./components/Repair/CreateRepair/CreateRepairGrid"));
const OrderContainer = React.lazy(() => import("./components/Order/OrderContainer"));
const PurchaseOrder = React.lazy(() => import("./components/PurchaseOrder/PurchaseOrder"));
const SalesGrid = React.lazy(() => import("./components/Sales/SalesGrid"));
const EndOfDay = React.lazy(() => import("./components/Sales/EndOfDay/EODContainer"));
// const Pricing = React.lazy(() => import("./components/Pricing/Pricing"));
const Checkout = React.lazy(() => import("./components/Checkout/ParentContainer"));

const MultiManager = React.lazy(() => import("./components/MultiManager/Manager"));

const CustomerGrid = React.lazy(() => {
  return import("./components/Customer/CustomerGrid");
});

const Appointment = React.lazy(() => {
  return import("./components/Appoinments/Appointments");
});

const InventoryCountList = React.lazy(() => {
  return import("./components/Inventory/Count/InventoryCountList");
});

const NewInventoryCount = React.lazy(() => {
  return import("./components/Inventory/Count/NewInventoryCount");
});

const PrepayContainer = React.lazy(() => {
  return import("./components/Prepay/PrepayContainer");
});

const Onboarding = React.lazy(() => {
  return import("./components/Payrix/Onboarding/Onboarding");
});

const FormikDeviceSelectionForm = React.lazy(() => {
  return import("./components/Checkout/Coverage/Coverage");
});

const App = (props) => {
  return (
    <GlobalState>
      <Switch>
        <Route path="/login" component={Auth} />
        <Route path="/logout" exact component={Signout} />
        <Route path="/forgot" exact component={EmailForgot} />
        <Route path="/reset/:token" exact component={PasswordReset} />

        <Route path="/ptpr-login/:access_token" component={PtprAuth} />
        <Route path="/register" exact>
          <HashRouter>
            <Route path="/:referral?" component={Register} />
          </HashRouter>
        </Route>

        <Route path="/verification" exact component={EmailVerification} />
        <Route path="/verification/:email" exact component={TokenVerification} />

        <Route path="/payment/:id" component={Payment} />
        <Route path="/plaid/auth" component={Plaid} />
        <Route path="/receipt/repair/:id" component={RepairReceipt} />
        <Route path="/receipt/:report" component={ReceiptContainer} />

        {/* <Route path="/processing_payment" component={Processing} /> */}
        <Route path="/processing-payment/:id?" exact component={Processing} />

        <Route path="/help" component={Help} />

        <Suspense fallback={<Loader />}>
          <Layout>
            <Suspense fallback={<Loader />}>
              <Route path="/multi-manager" exact component={MultiManager} />
              <Route path="/multi-manager/:id" component={ReportContainer} />
            </Suspense>
            {/* <Suspense fallback={<Loader />}>
              <Route path="/akko" exact component={FormikDeviceSelectionForm} />
            </Suspense> */}

            <AccountantRestrictedRoute>
              <Suspense fallback={<Loader />}>
                <Route path="/onboarding" component={Onboarding} />
              </Suspense>
              <Suspense fallback={<Loader />}>
                <Route path="/pricing/:payment_status?" component={Subscription} />
              </Suspense>

              <Suspense fallback={<Loader />}>
                <Route path="/" exact component={Homepage} />
                <Route path="/note" component={Homepage} />
              </Suspense>

              <Suspense fallback={<Loader />}>
                <Switch>
                  <Route path="/prepay" component={PrepayContainer} />
                </Switch>
              </Suspense>

              <Suspense fallback={<Loader />}>
                <Switch>
                  <Route path="/customer/:id" exact component={CustomerGrid} />
                  <Route path="/customer" component={CustomerGrid} />
                  <Route path="/appointment/customer/:id" exact component={Appointment} />
                  <Route path="/appointment/:id" exact component={Appointment} />
                  <Route path="/appointment" component={Appointment} />
                </Switch>
              </Suspense>

              <Suspense fallback={<Loader />}>
                <Switch>
                  <Route path="/inventory/count" exact component={InventoryCountList} />
                  <Route path="/inventory/count/new" component={NewInventoryCount} />
                  <Route path="/inventory" component={InventoryGrid} />
                  <Route path="/latestinventory" component={Inventory} />
                </Switch>
              </Suspense>

              <Suspense fallback={<Loader />}>
                <Route path="/employee" component={Employee} />
                <Route path="/employee/:id" component={EmployeeDetails} />
              </Suspense>

              <Suspense fallback={<Loader />}>
                <Route path="/report/:id?" exact component={ReportContainer} />
              </Suspense>

              <Suspense fallback={<Loader />}>
                <Switch>
                  <Route path="/sales" component={SalesGrid} />
                  <Route path="/end-of-day/:id?" component={EndOfDay} />
                </Switch>
              </Suspense>

              <Suspense fallback={<Loader />}>
                <Route path="/create-sales" component={Checkout} />
              </Suspense>

              <Suspense fallback={<Loader />}>
                <Switch>
                  <Route path="/settings" exact>
                    <HashRouter>
                      <Switch>
                        <Route path="/pricing" component={Subscription} />
                        <Route path="/:settingsKey?" exact component={SettingsContainer} />
                      </Switch>
                    </HashRouter>
                  </Route>
                </Switch>
              </Suspense>

              <Suspense fallback={<Loader />}>
                <Switch>
                  <Redirect strict from="/repair" exact to="/repairs" />
                  <Route path="/repairs" exact component={Repair} />
                  <Route path="/repair/customer/:id" component={Repair} />
                  <Route path="/repair/:id" component={RDetails} />
                </Switch>
              </Suspense>

              <Suspense fallback={<Loader />}>
                <Route path="/order/:id?" component={OrderContainer} />
              </Suspense>

              <Suspense fallback={<Loader />}>
                <Switch>
                  <Route path="/purchaseorder" exact>
                    <HashRouter>
                      <Route path="/" component={PurchaseOrder} />
                      <Route path="/repairshop" exact component={PurchaseOrder} />
                    </HashRouter>
                  </Route>
                </Switch>
              </Suspense>

              <Suspense fallback={<Loader />}>
                <Route path="/create-repair" exact component={CreateRepairGrid} />
                <Route path="/create-repair/:id" exact component={CreateRepairGrid} />
                <Route path="/quickrepair" exact component={CreateRepairGrid} />
                <Route path="/quickrepair/:id" exact component={CreateRepairGrid} />
              </Suspense>
              <Suspense fallback={<Loader />}>
                <Route path="/bulk_upload" exact component={BulkUpload} />
              </Suspense>

              <Suspense fallback={<Loader />}>
                <Route path="/last_login" exact component={LastLoginGrid} />
              </Suspense>
            </AccountantRestrictedRoute>
          </Layout>
        </Suspense>
      </Switch>
    </GlobalState>
  );
};

export default App;
