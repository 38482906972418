import React, { useState } from "react";
import { Field } from "formik";
import RadioGroup, {
  RadioInput,
} from "../../../custom_components/RadioGroup/RadioGroup";

const ContactInfo = ({ touched, errors, prev,isSubmitting, values }) => {
  const [state, setstate] = useState(false);

  return (
    <>
    <div className="">
        <div className="uk-container uk-container-large">
          {/* <div className="gap-8 d-flex-center">
            <div className="active-step"></div>
            <div className="inactive-step"></div>
            <div className="inactive-step"></div>
          </div> */}
        </div>
      </div>

    <div className="uk-grid-row-large uk-text-center">
      <span className="uk-text-bold uk-text-large">Create an account</span>
    </div>
    <div className="uk-grid-row-large uk-text-center">
      <span className="uk-text-muted uk-text-small-xs">Let's get your account ready by entering the following information.</span>
    </div>
    <div className="uk-margin-top">
      <h4 className="uk-margin-small uk-text-bold">Personal Information</h4>
      <div>
        <label className="uk-form-label" htmlFor="firstname">
          First Name
        </label>
        <div className="uk-form-controls">
          <Field
            className={[
              "uk-input border-radius-15",
              touched.firstName && errors.firstName ? "uk-form-danger" : "",
            ].join(" ")}
            id="firstname"
            name="firstName"
            type="text"
            placeholder="First Name*"
          />
        </div>
      </div>
      <div className="uk-margin">
        <label className="uk-form-label" htmlFor="lastname">
          Last Name
        </label>
        <div className="uk-form-controls">
          <Field
            className={[
              "uk-input border-radius-15",
              touched.lastName && errors.lastName ? "uk-form-danger" : "",
            ].join(" ")}
            id="lastname"
            name="lastName"
            type="text"
            placeholder="Last Name*"
          />
        </div>
      </div>
      <div className="uk-margin-small uk-text-center">
        
      </div>
      <div className="uk-margin">
        <label className="uk-form-label" htmlFor="phone">
          Phone{" "}
        </label>
        <div className="uk-form-controls">
          <Field
            className={[
              "uk-input border-radius-15",
              touched.phone && errors.phone ? "uk-form-danger" : "",
            ].join(" ")}
            id="phone"
            name="phone"
            type="text"
            value={values.phone}
            placeholder="phone number"
            onInput={(evt) => {
              evt.target.value = evt.target.value
                .replace(/[^0-9]/g, "")
                .replace(/(\..*)\./g, "$1");
            }}
          />
        </div>
      </div>

      <div className="uk-width-1-1">
        <label className="uk-form-label" htmlFor="city">
          Gender
        </label>

        <div className="uk-form-controls">
          <Field
            className={["uk-select border-radius-15", touched.gender && errors.gender ? "uk-form-danger" : ""].join(" ")}
            as="select"
            id="gender"
            name="gender"
          >
            <option value="" defaultChecked disabled={true}>Select</option>;
            <option value="male">Male</option>;
            <option value="female">Female</option>;
          </Field>
        </div>
      </div>
      {state && (
        <div className="uk-text-danger uk-text-small">
          All fields are required.
        </div>
      )}

      <div className="uk-margin  ">
        {/* <div>
          <div>
            <span
              uk-icon="chevron-double-left"
              className="uk-button uk-button-small uk-button-primary"
              onClick={prev}></span>
          </div>
        </div> */}
        <div>
          <div>
              <button type="submit"
              className={["uk-button border-radius-15 uk-width-1-1", errors.firstName || errors.lastName || errors.phone || errors.gender  ? "uk-button-secondary" : "background-design"].join(
                " "
              )}
              // className="uk-button border-radius-15 uk-width-1-1" 
              disabled={isSubmitting}>
              {isSubmitting ? <i className="fa fa-spinner fa-spin fa-fw"></i> : "Create account"}
            </button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ContactInfo;
