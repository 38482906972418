import React, { useState, useEffect } from "react";
import FormikConfirmationForm from "./Form";
import { withAuthentication } from "../../../hoc/withAuthentication";
import Alert from "../../../custom_components/alert";
import { Redirect } from "react-router";

const EmailVerification = ({ user, httpClient, history, match }) => {
  const [loading, setLoading] = useState(false);
  const [resending, setResending] = useState(false);
  const [apiResponse, setApiResponse] = useState({
    error: false,
    message: null,
  });

  useEffect(() => {
    checkVerification();
    resendToken();
  }, []);

  const resendToken = () => {
    setResending(true);

    if (!user._id) {
      history.push("/logout");
      return;
    }

    httpClient
      .post(`/account/resend_email/${user._id}`)
      .then((data) => {
        setApiResponse({
          error: false,
          message: "code has been sent to your mail.",
        });
      })
      .catch((err) => {
        setApiResponse({
          error: true,
          message: "Couldn't send code. Please check your Internet.",
        });
      })
      .finally((done) => {
        setResending(false);
      });
  };

  const checkVerification = () => {
    let interval;

    const recursive = () => {
      interval = setTimeout(() => {
        makeReq();
      }, 2000);
    };

    const makeReq = () => {
      httpClient
        .get(`/account/isverified/${user._id}`)
        .then((data) => {
          if (data.emailVerified) {
            clearTimeout(interval);
            user.updateUser(data);
            return;
          }
          recursive();
        })
        .catch((err) => {
          history.push("/logout");
          clearTimeout(interval);
        });
    };

    makeReq();
  };

  return !user._id ? (
    <Redirect to="/logout" />
  ) : (
    <section className="uk-flex uk-flex-center uk-flex-middle uk-text-center" uk-height-viewport="expand: true">
      <div>
        <div>
          <img data-src="https://repairspots.nyc3.digitaloceanspaces.com/SpotsPOSBranding/logo-official.svg" className="uk-height-small" alt="SpotsPOS" uk-img="" />
        </div>
        <div className="uk-text-lead">Confirm your email address</div>
        <p>
          We have sent a 6-digit verification code to{" "}
          <span className="uk-label uk-text-lowercase">
            <span className="">{user.email}</span>
          </span>
        </p>
        <p>Enter your code to verify your email.</p>

        <div className="uk-margin-auto">
          {!!apiResponse.message && (
            <Alert className={apiResponse.error ? "uk-alert-danger" : "uk-alert-success"}>{apiResponse.message}</Alert>
          )}
        </div>

        <FormikConfirmationForm setApiResponse={setApiResponse} email={user.email} loading={loading} />
        <p className="uk-text-small">
          didn't receive e-mail, check your spam or{" "}
          <button className="uk-text-small uk-text-lowercase uk-button uk-button-link">
            {" "}
            {resending ? (
              <span className="uk-text-small">waiting...</span>
            ) : (
              <span onClick={resendToken} className="uk-link tm-cursor-pointer">
                Resend code.
              </span>
            )}
          </button>
        </p>
        <p className="uk-text-small">
          Still having trouble receiving e-mail? Contact us at{" "}
          <button className="uk-text-small uk-text-lowercase uk-button uk-button-link">support@spotspos.com</button>
        </p>
      </div>
    </section>
  );
};

export default withAuthentication(EmailVerification);
