import React, { useEffect } from "react";
import { withAuthentication } from "@/hoc/withAuthentication";
import Alert2 from "./Alert2";
import { withRouter } from "react-router";

const ProtectedRoute = ({ pathname = "/", message, condition, children, history, user }) => {
  useEffect(() => {
    if (!condition && pathname) {
      history.push(pathname);
    }
  }, [condition, history.location]);

  return !!condition ? <React.Fragment>{children}</React.Fragment> : <Alert2 message={message} />;
};

export default withRouter(withAuthentication(ProtectedRoute));
