import React, { useState, useEffect } from "react";
import Login from "./Login/Login";
import { Redirect } from "react-router-dom";
import { withAuthentication } from "../../hoc/withAuthentication";
import * as actionTypes from "../../context/actions/actionTypes";
import { HttpFactory } from "../../RequestHandler";
import TwoFactorForm from "./Login/TwoFactor";
import Alert from "../../custom_components/alert";
import ShopLogin from "./ShopLogin";
import { warningAlert } from "@/utility/helpers";


const HttpClient = HttpFactory.getInstance();

const Auth = ({ user, history, httpClient }) => {
  const { selectedShop, type, status, dispatch, storesData } = user;
  const [loginInit, setLoginInit] = useState(false);
  const [resending, setResending] = useState(false);
  const [email, setEmail] = useState(null);
  const flag = (type && !/^(Admin|StoreAdmin|Accountant)$/i.test(user.type)) || /^(Fired)$/i.test(status); ///^customer$/i.test(type)

  const initLogin = ({ email, password }) => {
    dispatch({ type: actionTypes.AUTH_INIT });
    return HttpClient.initTwoFactorLogin(email, password)
      .then((data) => {
        if (!data) {
          return dispatch({
            type: actionTypes.AUTH_FAIL,
            error: "Username or Password not correct",
          });
        }

        if (data.twoFactor) {
          setEmail(email);
          setLoginInit(true);
          return;
        }

        if (data.uStatus == "Fired") {
          return dispatch({
            type: actionTypes.AUTH_FAIL,
            error: "Account Disabled",
          });
        }

        if (data.email) {
          const { token, ...acct } = data;

          dispatch({ type: actionTypes.AUTH_SUCCESS, acct });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.AUTH_FAIL,
          error: "Network error",
        });
      });
  };

  const confirmToken = (token) => {
    return HttpClient.initTwoFactorVerification(email, token)
      .then((data) => {
        if (!data) {
          return dispatch({
            type: actionTypes.AUTH_FAIL,
            error: "Invalid Token",
          });
        }
        if (data.email) {
          const { token, ...acct } = data;
          return dispatch({ type: actionTypes.AUTH_SUCCESS, acct });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.AUTH_FAIL,
          error: "Network error",
        });
      });
  };

  const resendToken = () => {
    setResending(true);
    return HttpClient.initResendToken(email)
      .catch((error) => {
        dispatch({ type: actionTypes.AUTH_FAIL, error: error.response.data });
      })
      .finally((data) => {
        setResending(false);
      });
  };

  useEffect(() => {
    if (flag) {
      warningAlert({
        message: "Portal access is only accessible to Store admin.",
      });

      return dispatch({ type: actionTypes.AUTH_RESET });
    }

    if (!flag && user?.email) {
      initSelectedShop(storesData[0]);
    }

    if (type == "Vendor") {
      window.location.href = "/purchaseorder";
    }
  }, [type, status, storesData]);

  const initSelectedShop = ({ _id, plan, isSuspended, status }) => {
    if (/true/i.test(isSuspended)) {
      warningAlert({
        message: `Your store is currently suspended. Contact us at support@spotspos.com`,
      });
      return history.push("/logout");
    }

    user.updateUser({...user,fromLogin:true});
    user.setShop(_id);

    const { emailVerified } = user;
    if (!emailVerified) {
      setTimeout(() => {
        window.location.href = "/verification";
      }, 500);
      return;
    }

    if (/^(Free)$/i.test(plan)) {
      return dispatch({
        type: actionTypes.AUTH_FAIL,
        error: "Please upgrade to a different plan to access the portal.",
      });
    }

    if (/accountant/i.test(user.type)) {
      return history.push("/multi-manager");
    }
  };

  return selectedShop ? (
    <Redirect to="/" />
  ) : (
    <>
      {!flag && user?.email && <Redirect to="/multi-manager" />}
     
      <div className="uk-container uk-text-center">
        <div className=" uk-flex uk-flex-center uk-grid-small" uk-grid={1}>
          <div className="uk-margin-medium-top uk-width-1-3@m">
            {/^customer$/i.test(type) && (
              <div className="uk-text-small uk-margin-auto">
                <Alert className="uk-alert-info">
                  Portal is only accessible to Admin and Store admin at the moment.
                </Alert>
              </div>
            )}

            {/^(Fired)$/i.test(status) && (
              <div className="uk-text-small uk-margin-auto">
                <Alert>
                  You no longer have access to your shop. Please contact your store manager for more information.
                </Alert>
              </div>
            )}
            <div className="uk-box-shadow-medium p-3 mt-5">
              {!loginInit && <Login initLogin={initLogin} />}
              {loginInit && (
                <TwoFactorForm
                  email={email}
                  status={status}
                  resendToken={resendToken}
                  resending={resending}
                  confirmToken={confirmToken}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuthentication(Auth);
