import React, { useEffect } from "react";

const Alert2 = ({ message = "You're all caught up.", status = "danger" }) => {
  useEffect(() => {
    window.UIkit.notification({
      message: `<div class='uk-text-center uk-text-small'>${message}</div>`,
      status,
    });
  }, [message, window.location.pathname]);
  return <React.Fragment />;
};

export default Alert2;
