import React, { useState } from "react";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import logo from "../../../assets/images/logo-rs.png";
import Alert from "../../../custom_components/alert";
import { withAuthentication } from "../../../hoc/withAuthentication";
import { withRouter } from "react-router";
import { warningAlert } from "@/utility/helpers";

const EmailForgot = (props) => {


  const { errors, values } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  }




  return (
    <div className="uk-container uk-padding">
      <div className="uk-width-large@m uk-text-center uk-margin-auto">
        {" "}
        {!!errors.api && <Alert>{errors.api}</Alert>}
      </div>
      <div className="uk-width-large@m  uk-margin-auto uk-box-shadow-small uk-padding uk-padding-remove-top">
        <div className="uk-text-center">
          <img
            className="uk-preserve-width uk-margin-auto uk-margin-top"
            src={'/icons/header/logo-sp.svg'}
            width="65%"
            auto="auto"
            alt="SpotsPOS"
          />
        </div>

        <div className="uk-margin-small-top">
          <h2 className="uk-text-bold">Reset Password</h2>
          <div>
            <span>Please enter your email address to reset your password.</span>
          </div>
          <Form className="uk-form-stacked">
            <div className="uk-margin uk-margin-top">
              <label
                className="uk-form-label uk-text-bold uk-text-left"
                htmlFor="form-stacked-text"
              >
                Email Address
              </label>

              <div className="uk-form-controls uk-inline uk-width-1-1@m">
                <span className="uk-form-icon" uk-icon="icon: mail"></span>
                <Field
                  onInput={(e) => { e.target.value = e.target.value.toLowerCase(); }}
                  className={[
                    "uk-input border-radius-15 uk-width-1-1@m", errors.email ? "uk-form-danger" : ""].join(" ")}
                  type="email"
                  name="email"
                  required={true}
                  placeholder="email@youremail.com"
                />
              </div>
            </div>
            <div className="uk-margin uk-margin-large-top">
              <button
                className={["uk-button border-radius-15 uk-width-1-1", errors.email || !values.email ? "uk-button-secondary" : "background-design"].join(
                  " "
                )}
                // uk-toggle="target: #my-id"
                // disabled={errors.email || !values.email}
                type={errors.email ? "button" : "submit"}
                onClick={handleShow}
              >
                Continue
              </button>
            </div>
          </Form>
        </div>
      </div>
      <div id="my-id" uk-modal={show.toString()} uk-width-1-4="true">
        <div className="uk-modal-dialog uk-modal-body ">
          <h6 className=" fs-4 uk-text-center  uk-text-bold">
            If this emial is associated to an account you will be
            receiving an email with a password reset link.
          </h6>
          <button
            className="uk-modal-close uk-align-center  fs-5 "
            type="button"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

const FormikForgot = withFormik({
  mapPropsToValues({
    email
  }) {
    return {
      email: email || "",
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string().email().required(),
  }),
  handleSubmit(values, { props, resetForm, setErrors, setSubmitting }) {
    const referral = props.match.params.referral;
    values.referalCode = referral || "";

    props.httpClient
      .post("/password/forgot", values)
      .then((res) => {
        console.log(res);
        warningAlert({
          message:
            res.message,
          status: "success",
        });
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        if (err.message) {
          setErrors({ api: err.message });
          setTimeout(() => {
            window.location.href = "/login";
          }, 3000);
          return;
        }
        setErrors({ api: "something went wrong. Try again." });
      })
      .finally((done) => {
        setSubmitting(false);
      });
  },
})(EmailForgot);

export default withRouter(withAuthentication(FormikForgot));
