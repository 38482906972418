import React from "react";
import classes from "./Preloader.module.css";

const Loader = (props) => {
  return (
    <div className={classes.Loader}>
      <span className={""}>
        <span className={classes.LoaderInner}></span>
      </span>
    </div>
  );
};

export default Loader;
