export const UPLOAD_BULKDATA = "UPLOAD_BULKDATA";
export const START_UPLOAD = "START_UPLOAD";
export const SET_BULKDATA = "SET_BULKDATA";
export const START_BULKDATA = "START_BULKDATA";
export const CLEAR_BULKTABLE = "CLEAR_BULKTABLE";
export const CLEAR_FAILEDUPLOAD = "CLEAR_FAILEDUPLOAD";
export const SET_FAILEDUPLOAD = "SET_FAILEDUPLOAD";
export const SEND_BULKDATA = "SEND_BULKDATA";

export const AUTH_INIT = "AUTH_INIT";
export const AUTH_RESET = "AUTH_RESET";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";
export const SET_SHOP = "SET_SHOP";
export const SET_CURRENCY = "SET_CURRENCY";
export const UPDATE_SHOP = "UPDATE_SHOP";
export const SET_VENDOR = "SET_VENDOR";
export const SET_STORELOGOIMG = "SET_STORELOGOIMG";
export const SET_SIDEBARSTATE = "SET_SIDEBARSTATE";
export const SET_TERMINAL = "SET_TERMINAL";
export const SET_PFKEY = "SET_PFKEY";
export const SET_TAX = "SET_TAX";
export const TOGGLE_TERMINAL = "TOGGLE_TERMINAL";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
