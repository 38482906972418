import React from "react";
import classes from "./RadioGroup.module.css";
import { Field } from "formik";

const RadioGroup = ({ children, className = "" }) => {
  return (
    <div className={[classes.RadioGroup, className].join(" ")}>{children}</div>
  );
};

export default RadioGroup;

export const RadioInput = (props) => {
  return (
    <span className="notranslate">
      <Field type="radio" id={`option-${props.label}`} {...props} />
      <label htmlFor={`option-${props.label}`}>{props.label}</label>
    </span>
  );
};
