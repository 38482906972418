import React, { useContext, useState } from "react";
import Preloader from "@/custom_components/Preloader/Preloader";

const SpinnerContext = React.createContext();

const SpinnerProvider = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);

  return (
    <>
      <SpinnerContext.Provider
        value={{
          showSpinner,
          setShowSpinner,
        }}
        {...props}
      />

      {showSpinner && <Preloader />}
    </>
  );
};

function useSpinner() {
  const context = useContext(SpinnerContext);

  if (context === undefined) {
    throw new Error(`useSpinner must be used within a SpinnerProvider`);
  }
  return context;
}

export { SpinnerProvider, useSpinner };
