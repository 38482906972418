import React from "react";

const Modal = ({ children, title }) => {
  return (
    <div
      id="my-modal"
      className="uk-flex-top uk-flex uk-open uk-position-absolute"
      uk-modal={1}>
      <div className="uk-modal-dialog uk-text-center uk-margin-auto-vertical">
        {title && (
          <div className="uk-modal-header">
            <h4>{title}</h4>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
