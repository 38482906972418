import React, { useEffect } from 'react'
import { withFormik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { withAuthentication } from '../../../hoc/withAuthentication';
import { compose } from '../../../constants/utility';
import Alert from '../../../custom_components/alert';



const TwoFactor = ({
    user,
    email,
    resendToken,
    resending,
    isSubmitting,
    errors,
    setErrors,

}) => {

    const { error } = user;

    useEffect(() => {
        if (error) {
            setErrors({ email: error })
        }
    }, [error, errors])


    const tokenInputHandler = (evt) => {
        let value = evt.target.value;
        if (value) {
            if (value.length > 6) {
                evt.target.value = String(value).substr(0, 6);
            }
            else {
                evt.target.value = String(value).toUpperCase();

            }
        }
    }


    return (

        <div className="uk-width-2-3@m uk-margin-auto">
            {error && <Alert>{errors.email}</Alert>}
            <Form className="uk-form-stacked uk-text-center">

                <div>
                    <label className="uk-text-justify uk-form-label uk-text-small">Enter the 6 character code we sent to {email}</label>

                    <div className="uk-form-controls uk-inline uk-width-expand@m">
                        <span className="uk-form-icon" uk-icon="icon: lock"></span>
                        <Field className={["uk-input", errors.token ? 'uk-form-danger' : ''].join(" ")}
                            type="text" name="token"
                            placeholder=""
                            onInput={tokenInputHandler} />
                    </div>
                    {(errors.token) && <div className="uk-text-danger uk-text-small uk-margin-left">{errors.token}</div>}
                </div>

                <div className="uk-text-small">
                    {resending ? <span className="uk-text-small">waiting...</span> : <span onClick={resendToken} className="uk-link tm-cursor-pointer">Resend code.</span>}
                </div>

                <button type="submit" className=" uk-margin-small-top uk-button uk-button-secondary uk-width-1-1" disabled={isSubmitting}>{isSubmitting ? <i className="fa fa-spinner fa-spin fa-fw"></i> : 'Submit'}</button>
            </Form>
        </div>
    )
}


const FormikTwoFactor = withFormik({

    mapPropsToValues({ token }) {
        return {
            token: token || ''
        }
    },
    validationSchema: Yup.object().shape({
        token: Yup.string().min(6).max(6).required("six characters expected")
    }),
    handleSubmit(values, { props, resetForm, setErrors, setSubmitting }) {
        props.confirmToken(values.token)
            .then(data => setSubmitting(false));
    }
})(TwoFactor)

const TwoFactorForm = compose(
    withAuthentication,
)(FormikTwoFactor)


export default TwoFactorForm;