import React, { useEffect, useRef } from "react";

const Help = () => {
  return (
    <>
      <iframe
        name="myiframe"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        width="100%"
        height="100%"
        seamless="seamless"
        className="iframe"
        src={"https://dev-portal.repairspots.com/rs-support"}
      ></iframe>

      <style type="text/css" jsx="">
        {`
          iframe.iframe {
            margin: 0px;
            padding: 0px;
            height: 100%;
            border: none;
          }

          iframe.iframe {
            display: block;
            width: 100%;
            border: none;
            overflow-y: auto;
            overflow-x: hidden;
          }
        `}
      </style>
    </>
  );
};

export default Help;
