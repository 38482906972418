import React, { useEffect } from "react";
import Spinner from "../../custom_components/Spinner/Spinner";
import { withAuthentication } from "@/hoc/withAuthentication";
import { warningAlert } from "@/utility/helpers";
import { withRouter } from "react-router";

const Payment = ({ match, httpClient, history, user }) => {
  const id = match.params.id;
  const params = new URLSearchParams(history.location.search);
  // const transaction = params.get("transaction");

  useEffect(() => {
    httpClient
      .get(`/payment/receipt/${id}?${params.toString()}`)
      .then((res) => {
        const { redirect } = res;

        window.location = redirect;
      })
      .catch((err) => {
        warningAlert({ message: "Transaction not found.", status: "danger" });
      });
  }, []);

  return (
    <Spinner>
      <div className="uk-light">Loading transaction receipt</div>
      <div className="uk-light uk-text-bold">Do not close this window</div>
    </Spinner>
  );
};

export default withRouter(withAuthentication(Payment));
